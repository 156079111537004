<template>
  <el-dialog
    class="bind-admin-dialog"
    title="绑定健身房管理员"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="close"
    width="620px"
  >
    <!--    <div class="warning-tip">-->
    <!--      请注意，如果该球场已有管理员绑定，重新选择管理员将覆盖原有管理员，请谨慎操作。-->
    <!--    </div>-->
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px" style="padding-right: 0;">
      <el-form-item label="校区" label-width="60px" required>
        <el-radio-group v-model="formData.campusId" size="small" @change="getManagesList">
          <el-radio-button v-for="{campusName,id} in campusList" :key="id" :label="id">
            {{ campusName }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="搜索" label-width="60px" required>
        <tool-tips-group tips-content="名字搜索最少输入2个汉字。<br/>
                         身份证/录取编号搜索最少输入6位数字。<br/>
                         <b class='font-danger'>教职工搜索只显示在住的教职工。</b><br/>"
                         style="margin-top: 6px;margin-bottom: var(--space-primary);">
          <!--   <br/>只能搜索所管辖学院的学生。  <span>搜索学生：</span>-->
          <el-radio-group class="mr-1" v-model="formData.userType" size="small" style="margin-top: -4px">
            <el-radio-button :label="2">搜索学生</el-radio-button>
            <el-radio-button :label="3">搜索教师</el-radio-button>
          </el-radio-group>
          <el-autocomplete
            v-model="userInfo.value"
            :fetch-suggestions="querySearchAsync"
            :placeholder="formData.userType===2?'学生名、身份证号、录取编号':'教师姓名、身份证、工号'"
            size="small"
            clearable
            @select="handleSelect" style="display: inline-block;width:288px;" />
        </tool-tips-group>
      </el-form-item>
      <el-card shadow="never">
        <el-form-item label="已选择学生">
          <template v-if="formData.stuIds.length">
            <el-tag v-for="tag in formData.stuIds" :key="tag.id" closable style="margin-right: 10px;"
                    @close="removeTag(tag,'stuIds')">{{ tag.stuName }}({{ tag.stuNo }})
            </el-tag>
          </template>
          <div v-else class="font-grey">--请选择学生核销员--</div>
        </el-form-item>
      </el-card>
      <el-divider />

      <el-card shadow="never">
        <el-form-item label="已选择教职工">
          <template v-if="formData.teacherIds.length">
            <el-tag v-for="tag in formData.teacherIds" :key="tag.id" class="mr-1" closable type="success"
                    @close="removeTag(tag,'teacherIds')">{{ tag.realName }}({{ tag.username }})
            </el-tag>
          </template>
          <div v-else class="font-grey">--请选择教职工核销员--</div>
        </el-form-item>
      </el-card>
    </el-form>
    <template #footer>
      <el-button @click="close">关 闭</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import {
  batchBindGymCourtByUserIdApi,
  listGymCourtApi, batchBindManagersByCourtApi, listCourtStuApi, listCourtTeacherApi
} from '@/api/student-manage/gym-court-api'
import { getUserList } from '@/api/system/user-api'
import userAutocomplete from '@/vue/mixins/user-autocomplete'

export default {
  name: 'BindAdminDialog',
  mixins: [dialog, userAutocomplete],
  props: {
    campusId: { // 把父级当前的campusId作为默认值
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formData: {
        campusId: '',
        userType: 2,
        stuIds: [],
        teacherIds: []
      },
      userInfo: { value: '' },
      userList: [],
      keyword: '',
      campusList: [], // 校区list
      courtList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  async mounted() {
    await this.getCampusList()
    if (this.formData.campusId) {
      await this.getManagesList(this.formData.campusId)
    }
  },
  methods: {
    async getManagesList(campusId) {
      try {
        // 学生
        const res = await listCourtStuApi({
          campusId,
          pageNum: 1,
          pageSize: 99
        })
        this.formData.stuIds = res.data || []
        // 教师
        const res2 = await listCourtTeacherApi({
          campusId,
          pageNum: 1,
          pageSize: 99
        })
        this.formData.teacherIds = res2.data || []
      } catch (e) {
        console.error(e)
      }
    },
    // 获取校区
    async getCampusList() {
      try {
        const res = await this.$http.getCampusListQuery({})
        this.campusList = res.data
        this.formData.campusId = this.campusId || ''
      } catch (e) {
        console.error(e)
      }
    },
    // 场地list
    async getCourtList(campus, arr) {
      try {
        const res = await listGymCourtApi({ campusId: campus.id })
        // 获取每个校区的场地list
        arr.push({
          id: campus.id,
          name: campus.campusName,
          children: res.data
        })
      } catch (e) {
        console.error(e)
      }
    },
    // 根据校区获取场地list
    handleCourtList(val) {
      this.courtList = this.campusList.find(item => item.id === val)?.children || []
    },
    // 远程搜索 教职工名
    async querySearchAsyncBak(keyword, cb) {
      let _result = []
      /* 判断是否请求接口 为false 时请求接口
      * 默认为 true ,因为为false+keyword===''时加载所有的几万条教职工数据
      * */
      let _correctQuery = false
      if (keyword) {
        if (!isNaN(keyword)) {
          // 支持学号、身份证号(number)搜索,但是学号/考号/身份证号必须填入6位数以上,减轻服务器压力
          if (keyword.length > 5) {
            _correctQuery = true
          }
        } else if (keyword.length) {
          // 为string,且关键字最少1个字才能开始搜索,减轻服务器压力
          _correctQuery = true
        }
      }
      if (_correctQuery) {
        const _query = {
          keyword: keyword,
          pageNum: 1,
          userType: 3,
          pageSize: 1000
        }
        try {
          const res = await getUserList(_query)
          res.data.list.map((item) => {
            Object.assign(item, { value: item.realName })
          })
          _result = res.data.list
        } catch (e) {
        } finally {
          cb(_result)
        }
      } else {
        cb(_result)
      }
    },
    async bindCourtByUserId(data) {
      try {
        await batchBindGymCourtByUserIdApi({ data })
      } catch (e) {
        console.error(e)
      }
    },
    // 批量绑定学生和教师
    async bindManagers(data) {
      this.$confirm('是否确定绑定以下学生/教职工为该场地管理员？', '绑定提醒').then(() => {

      })
      try {
        await batchBindManagersByCourtApi({ data })
      } catch (e) {
        console.error(e)
      }
    },
    // 获取选中的教职工信息
    handleSelect(person) {
      const arrName = this.formData.userType === 2 ? 'stuIds' : 'teacherIds'
      const hasRepeat = this.formData[arrName].some(item => person.id === item.id)
      if (hasRepeat) {
        this.$message.warning(`该${this.formData.userType === 2 ? '学生' : '教职工'}管理员已存在`)
      } else {
        this.formData[arrName].push(person)
        this.keyword = ''
        this.userInfo.value = ''
      }
    },
    removeTag(tag, tagName) {
      this.formData[tagName] = this.formData[tagName].filter(item => item.id !== tag.id)
    },
    async handleSave(params) {
      try {
        await batchBindManagersByCourtApi({
          ...params,
          campusId: this.formData.campusId
        })
      } catch (e) {
        console.error(e)
      }
    },
    // 批量绑定
    save() {
      if (!this.formData.campusId) {
        this.$message.error('请先选择校区')
      } else {
        this.$confirm('是否确认绑定场地核销员？', '绑定确认').then(async () => {
          try {
            const _stuIds = this.formData.stuIds.map(item => item.userId)
            const _teacherIds = this.formData.teacherIds.map(item => item.id)
            await Promise.all([
              this.handleSave({
                userType: 2,
                userIds: _stuIds
              }),
              this.handleSave({
                userType: 3,
                userIds: _teacherIds
              })
            ])
            this.$message.success('核销员设置成功！')
            this.closed()
          } catch (e) {
            this.$message.error(e)
          }
        }).catch(e => {
          this.$message.info('已取消')
          console.error(e)
        })
      }
    }

  }
}
</script>
<style lang="scss">
.bind-admin-dialog {
  .el-dialog__body {
    padding-top: 0;
  }

  .el-card {
    .el-card__body {
      padding: 8px;
    }
  }

  .tree-scroll {
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .el-tree {

    > .el-tree-node {
      > .el-tree-node__content {
        > .el-checkbox {
          display: none;
        }
      }
    }

    .el-tree-node__children {
      display: flex;
      flex-wrap: wrap;

      .el-tree-node {
        min-width: 30%;

        .el-tree-node__content {
          padding-left: 0 !important;
          padding-right: 1em;
        }

        .is-leaf {
          //width: 0;
        }
      }
    }
  }
}
</style>
