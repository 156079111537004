<!--按场地绑定/解绑管理员-->
<template>
  <el-dialog
    class="single-bind-admin-dialog"
    :title="`设置健身房管理员【场地：${parentData.campusName}${parentData.name}】`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="620px"
  >
    <el-form ref="form" :rules="rules" label-width="100px" style="padding-right: 0;">
      <el-form-item label="搜索管理员" prop="keyword">
        <el-autocomplete
          v-model="keyword"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入教职工名"
          @select="handleSelect" />
      </el-form-item>
      <el-form-item label="已选择管理员">
        <el-tag v-for="tag in userList" :key="tag.id" closable style="margin-right: 10px;"
                @close="removeTag(tag)">{{ tag.realName }}
        </el-tag>
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import {
  batchBindGymCourtByUserIdApi
} from '@/api/student-manage/gym-court-api'
import { getUserList } from '@/api/system/user-api'

export default {
  name: 'SingleBindAdminDialog',
  mixins: [dialog],
  data() {
    return {
      keyword: '', // 远程搜索管理员
      userList: [], // 显示的 管理员list
      parentData: {},
      formData: {
        roomUsers: []
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.parentData.id) {
        this.getDataByParentData()
      }
    })
  },
  methods: {
    // 获取parentData的数据赋值给formData
    getDataByParentData() {
      if (this.parentData.managers.length) {
        this.userList = this.parentData.managers
        this.formData.roomUsers = this.parentData.managers.map(item => {
          return {
            userId: item.id,
            gymRoomId: this.parentData.id
          }
        })
        this.setInitData()
      }
    },
    // 远程搜索 教职工名
    async querySearchAsync(keyword, cb) {
      let _result = []
      /* 判断是否请求接口 为false 时请求接口
      * 默认为 true ,因为为false+keyword===''时加载所有的几万条教职工数据
      * */
      let _correctQuery = false
      if (keyword) {
        if (!isNaN(keyword)) {
          // 支持学号、身份证号(number)搜索,但是学号/考号/身份证号必须填入6位数以上,减轻服务器压力
          if (keyword.length > 5) {
            _correctQuery = true
          }
        } else if (keyword.length) {
          // 为string,且关键字最少1个字才能开始搜索,减轻服务器压力
          _correctQuery = true
        }
      }
      if (_correctQuery) {
        const _query = {
          keyword: keyword,
          pageNum: 1,
          userType: 3,
          pageSize: 1000
        }
        try {
          const res = await getUserList(_query)
          res.data.list.map((item) => {
            Object.assign(item, { value: item.realName })
          })
          _result = res.data.list
        } catch (e) {
        } finally {
          cb(_result)
        }
      } else {
        cb(_result)
      }
    },
    async bindCourtByUserId(data) {
      try {
        await batchBindGymCourtByUserIdApi({ data })
      } catch (e) {
        console.error(e)
      }
    },
    // 获取选中的教职工信息
    handleSelect(teac) {
      const hasRepeat = this.userList.some(item => item.id === teac.id)
      if (hasRepeat) {
        this.$message.warning('该管理员已存在')
      } else {
        this.userList.push(teac)
        this.keyword = ''
      }
    },
    removeTag(tag) {
      this.userList = this.userList.filter(item => item.id !== tag.id)
    },
    // 批量绑定
    save() {
      if (!this.userList.length) {
        this.$message.error('请先选择管理员')
      } else {
        this.$confirm('是否确认调整管理员？', '提示').then(async () => {
          try {
            const roomUsers = []
            this.userList.forEach(user => {
              roomUsers.push({
                userId: user.id,
                gymRoomId: this.parentData.id
              })
            })
            await batchBindGymCourtByUserIdApi({ roomUsers })
            this.$message.success('管理员调整成功')
            this.closed()
          } catch (e) {
            this.$message.error(e)
          }
        })
      }
    }

  }
}
</script>
<style lang="scss">
.single-bind-admin-dialog {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
