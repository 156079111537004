import request from '@/service/request'

/* 健身房闸机，根据健身房id获取list
* @params {string} gymRoomId 健身房id
* @params {string} enable 是否启用门闸 0 否 1是
* @params {string} keyword 关键字
* */
export const listGymDoorByCourtIdApi = (params = {}) => {
  return request.post('/stu/gymDoor/listByRoomId', params)
}

/* 健身房闸机，批量禁用门闸
* @params {string} id 门闸ids 通过逗号拼接 （） /id1,id2,id3
* */
export const batchBanGymDoorApi = (ids) => {
  return request.post(`/stu/gymDoor/offBatch/${ids}`)
}

/* 健身房闸机，保存 (启用也是这个接口)
* @params
* */
export const saveGymDoorApi = (params) => {
  return request.post('/stu/gymDoor/save', params)
}
