import request from '@/service/request'

/* 健身房排期规则，分页
* @params
* */
export const pageGymRuleApi = (params) => {
  return request.post('/stu/gymRule/page', params)
}

/* 健身房排期规则，list
* @params
* */
export const listGymRuleApi = (params = {}) => {
  return request.post('/stu/gymRule/list', params)
}

/* 健身房排期规则，根据id获取
* @params
* */
export const getGymRuleByIdApi = (id) => {
  return request.post(`/stu/gymRule/get/${id}`)
}

/* 健身房排期规则，根据校区id获取
* @params
* */
export const getGymRuleByCampusIdApi = (CampusId) => {
  return request.post(`/stu/gymRule/getByCampusId/${CampusId}`)
}
/* 健身房排期规则，删除
* @params
* */
export const delGymRuleByIdApi = (id) => {
  return request.post(`/stu/gymRule/del/${id}`)
}

/* 健身房排期规则，保存
* @params
* */
export const saveGymRuleApi = (params) => {
  return request.post('/stu/gymRule/save', params)
}
