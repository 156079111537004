var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"gym-rule-dialog",attrs:{"title":"健身房校区排期规则","visible":_vm.show,"close-on-click-modal":false,"before-close":_vm.closed,"width":"1320px"},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-button',{on:{"click":_vm.closed}},[_vm._v("关闭")])]},proxy:true}])},[_c('div',{staticClass:"tip"},[_vm._v(" 规则会对整个校区的健身房排期生效。"),_c('br'),_vm._v(" 一个校区只能存在一个规则。 ")]),_c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo,"header-right":""},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"tool-list":['campus'],"queryInfo":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}})]},proxy:true},{key:"header-right",fn:function(){return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['gym:rule:edit']),expression:"['gym:rule:edit']"}],attrs:{"type":"primary","size":"small"},on:{"click":_vm.newRow}},[_vm._v("新增规则 ")])]},proxy:true}])},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"formRef",staticStyle:{"padding-right":"0"},attrs:{"model":_vm.formData,"rules":_vm.rules}},[_c('el-table',{attrs:{"data":_vm.formData.tableData}},[_c('el-table-column',{attrs:{"label":"校区","prop":"campusName","width":"110px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEditing)?_c('el-form-item',{attrs:{"prop":("tableData." + $index + ".campusId"),"rules":_vm.rules.campusId}},[_c('el-select',{ref:"roleREf",attrs:{"size":"small","placeholder":"校区","clearable":"","filterable":""},model:{value:(row.campusId),callback:function ($$v) {_vm.$set(row, "campusId", $$v)},expression:"row.campusId"}},_vm._l((_vm.campusList),function(ref){
var campusName = ref.campusName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"disabled":_vm.formData.tableData.some(function (item) { return item.campusId === id&&row.campusId!==id; }),"label":campusName,"value":id}})}),1)],1):_c('span',[_vm._v(_vm._s(row.campusName))])]}}])}),_c('el-table-column',{attrs:{"label":"规则名","min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEditing)?_c('el-form-item',{attrs:{"prop":("tableData." + $index + ".name"),"rules":_vm.rules.name}},[_c('el-input',{attrs:{"size":"small"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})],1):_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"单人单日可预约次数上限","width":"110px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.dayOrderMax))])]}}])}),_c('el-table-column',{attrs:{"label":"单个时段可容纳人数上限","width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEditing)?_c('el-form-item',{attrs:{"prop":("tableData." + $index + ".scheduleOrderMax"),"rules":_vm.rules.scheduleOrderMax}},[_c('el-input-number',{attrs:{"min":1,"precision":0,"max":3600,"size":"small","controls-position":"right"},model:{value:(row.scheduleOrderMax),callback:function ($$v) {_vm.$set(row, "scheduleOrderMax", $$v)},expression:"row.scheduleOrderMax"}})],1):_c('span',[_vm._v(_vm._s(row.scheduleOrderMax)+"个")])]}}])}),_c('el-table-column',{attrs:{"width":"140px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tool-tips-group',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticStyle:{"line-height":"1.8"}},[_vm._v(" 规则说明‌："),_c('br'),_vm._v(" 核销提前时间指的是在预约时段开始之前，"),_c('br'),_vm._v(" 允许用户出示二维码进行核销的时间范围。"),_c('br'),_vm._v(" 单位是分钟。"),_c('br'),_vm._v(" 比如设置提前时间为 10 分钟:"),_c('br'),_vm._v(" 若会员预约的时间段是 15:00 - 16:00，那么在 14:50 - 15:00 这个时间段内，"),_c('br'),_vm._v(" 会员就可以出示二维码让工作人员进行核销。 ")])]},proxy:true}])},[_vm._v(" 核销提前时间(分钟) ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEditing)?_c('el-form-item',{attrs:{"prop":("tableData." + $index + ".preFixedTime"),"rules":_vm.rules.preFixedTime}},[_c('el-input-number',{attrs:{"min":0,"max":120,"precision":0,"size":"small","controls-position":"right"},model:{value:(row.preFixedTime),callback:function ($$v) {_vm.$set(row, "preFixedTime", $$v)},expression:"row.preFixedTime"}})],1):_c('span',[_vm._v(_vm._s(row.preFixedTime))])]}}])}),_c('el-table-column',{attrs:{"width":"140px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tool-tips-group',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticStyle:{"line-height":"1.8"}},[_vm._v(" 规则说明‌："),_c('br'),_vm._v(" 设置自动取消时间（如10分钟）后，若用户预约时段为15:00-16:00： ")]),_c('ul',{staticStyle:{"padding-left":"1em"}},[_c('li',{staticStyle:{"list-style":"disc"}},[_vm._v(" 15:10系统自动取消预约，同时，用户会被加入黑名单，此后将无法再次预约场地。 ")]),_c('li',{staticStyle:{"list-style":"disc"}},[_vm._v("并且，在该时段（15:00 - 16:00）内，场地将处于空置状态，不可进行预约操作。 ")])])]},proxy:true}])},[_vm._v(" 预约自动取消时间(分钟) ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEditing)?_c('el-form-item',{attrs:{"prop":("tableData." + $index + ".cancelFixedTime"),"rules":_vm.rules.cancelFixedTime}},[_c('el-input-number',{attrs:{"min":0,"max":999,"precision":0,"size":"small","controls-position":"right"},model:{value:(row.cancelFixedTime),callback:function ($$v) {_vm.$set(row, "cancelFixedTime", $$v)},expression:"row.cancelFixedTime"}})],1):_c('span',[_vm._v(_vm._s(row.cancelFixedTime))])]}}])}),_c('el-table-column',{attrs:{"label":"校区公告","width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEditing)?_c('el-form-item',{attrs:{"prop":("tableData." + $index + ".announceText"),"rules":_vm.rules.announceText}},[_c('el-input',{attrs:{"type":"textarea","rows":"3","size":"small"},model:{value:(row.announceText),callback:function ($$v) {_vm.$set(row, "announceText", $$v)},expression:"row.announceText"}})],1):_c('span',[_vm._v(_vm._s(row.announceText))])]}}])}),_c('el-table-column',{attrs:{"label":"备注","width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEditing)?_c('el-form-item',{attrs:{"prop":("tableData." + $index + ".remarks"),"rules":_vm.rules.remarks}},[_c('el-input',{attrs:{"type":"textarea","rows":"3","size":"small"},model:{value:(row.remarks),callback:function ($$v) {_vm.$set(row, "remarks", $$v)},expression:"row.remarks"}})],1):_c('span',[_vm._v(_vm._s(row.remarks))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"160px","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEditing)?[_c('el-form-item',[(row.isEditing)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['gym:rule:edit']),expression:"['gym:rule:edit']"}],attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.save(row)}}},[_vm._v("保存 ")]):_vm._e(),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.cancel(row,$index)}}},[_vm._v("取消")])],1)]:[(!_vm.isEditing)?[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['gym:rule:edit']),expression:"['gym:rule:edit']"}],attrs:{"size":"mini","type":"primary","plain":""},on:{"click":function($event){return _vm.editRow(row,$index)}}},[_vm._v("编辑 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['gym:rule:edit']),expression:"['gym:rule:edit']"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(_vm.delGymRuleByIdApi, row.id,("排期规则-" + (row.name)))}}},[_vm._v(" 删除 ")])]:_vm._e()]]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }