<template>
  <!--健身房排班规则-->
  <el-dialog
    class="gym-rule-dialog"
    title="健身房校区排期规则"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="closed"
    width="1320px"
  >
    <div class="tip">
      规则会对整个校区的健身房排期生效。<br />
      一个校区只能存在一个规则。
    </div>
    <table-view :total="total"
                :query-info.sync="queryInfo"
                header-right
                @reload-table="renderTable">
      <template #header>
        <view-search-form
          ref="searchFormRef"
          :tool-list="['campus']"
          :queryInfo.sync="queryInfo"
          @on-search="renderTable(1)" />
      </template>
      <template #header-right>
        <el-button v-permission="['gym:rule:edit']" type="primary" size="small" @click="newRow">新增规则
        </el-button>
      </template>
      <el-form ref="formRef" :model="formData" v-loading="loading" :rules="rules" style="padding-right: 0;">
        <el-table :data="formData.tableData">
          <el-table-column label="校区" prop="campusName" width="110px">
            <template v-slot="{row,$index}">
              <el-form-item
                v-if="row.isEditing"
                :prop="`tableData.${$index}.campusId`"
                :rules="rules.campusId">
                <el-select ref="roleREf" size="small" v-model="row.campusId" placeholder="校区"
                           clearable
                           filterable>
                  <el-option v-for="{campusName,id} in campusList" :key="id"
                             :disabled="formData.tableData.some(item => item.campusId === id&&row.campusId!==id)"
                             :label="campusName"
                             :value="id" />
                </el-select>
              </el-form-item>
              <span v-else>{{ row.campusName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="规则名" min-width="140px">
            <template v-slot="{row,$index}">
              <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.name`"
                            :rules="rules.name">
                <el-input size="small" v-model="row.name" />
              </el-form-item>
              <span v-else>{{ row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单人单日可预约次数上限" width="110px" align="center">
            <template v-slot="{row}">
              <!--              <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.dayOrderMax`"-->
              <!--                            :rules="rules.dayOrderMax">-->
              <!--                <el-input-number :min="1" :precision="0" :max="3600" size="small"-->
              <!--                                 controls-position="right"-->
              <!--                                 v-model="row.dayOrderMax" />-->
              <!--              </el-form-item>-->
              <span>{{ row.dayOrderMax }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单个时段可容纳人数上限" width="160px">
            <template v-slot="{row,$index}">
              <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.scheduleOrderMax`"
                            :rules="rules.scheduleOrderMax">
                <el-input-number :min="1" :precision="0" :max="3600" size="small"
                                 controls-position="right"
                                 v-model="row.scheduleOrderMax" />
              </el-form-item>
              <span v-else>{{ row.scheduleOrderMax }}个</span>
            </template>
          </el-table-column>
          <el-table-column width="140px">
            <template #header>
              <tool-tips-group>
                <template #content>
                  <p style="line-height: 1.8">
                    规则说明‌：<br />
                    核销提前时间指的是在预约时段开始之前，<br />
                    允许用户出示二维码进行核销的时间范围。<br />
                    单位是分钟。<br />
                    比如设置提前时间为 10 分钟:<br />
                    若会员预约的时间段是 15:00 - 16:00，那么在 14:50 - 15:00 这个时间段内，<br />
                    会员就可以出示二维码让工作人员进行核销。
                  </p>
                </template>
                核销提前时间(分钟)
              </tool-tips-group>
            </template>
            <template v-slot="{row,$index}">
              <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.preFixedTime`"
                            :rules="rules.preFixedTime">
                <el-input-number :min="0" :max="120" :precision="0" size="small"
                                 controls-position="right"
                                 v-model="row.preFixedTime" />
              </el-form-item>
              <span v-else>{{ row.preFixedTime }}</span>
            </template>
          </el-table-column>
          <el-table-column width="140px">
            <template #header>
              <tool-tips-group>
                <template #content>
                  <p style="line-height: 1.8">
                    规则说明‌：<br />
                    设置自动取消时间（如10分钟）后，若用户预约时段为15:00-16:00：
                  </p>
                  <ul style="padding-left: 1em;">
                    <li style="list-style: disc;">
                      15:10系统自动取消预约，同时，用户会被加入黑名单，此后将无法再次预约场地。
                    </li>
                    <li style="list-style: disc;">并且，在该时段（15:00 - 16:00）内，场地将处于空置状态，不可进行预约操作。
                    </li>
                  </ul>
                </template>
                预约自动取消时间(分钟)
              </tool-tips-group>
            </template>
            <template v-slot="{row,$index}">
              <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.cancelFixedTime`"
                            :rules="rules.cancelFixedTime">
                <el-input-number :min="0" :max="999" :precision="0" size="small"
                                 controls-position="right"
                                 v-model="row.cancelFixedTime" />
              </el-form-item>
              <span v-else>{{ row.cancelFixedTime }}</span>
            </template>
          </el-table-column>
          <!--       禁用暂时没找到用处   -->
          <!--          <el-table-column width="220" align="center">-->
          <!--            <template #header>-->
          <!--              <tool-tips-group tips-content="禁用规则后，该规则下的所有场地将不可用。">是否禁用</tool-tips-group>-->
          <!--            </template>-->
          <!--            <template v-slot="{row,$index}">-->
          <!--              <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.offFlag`">-->
          <!--                <el-switch-->
          <!--                  v-permission="['gym:rule:edit']"-->
          <!--                  v-model="row.offFlag"-->
          <!--                  active-color="var(&#45;&#45;color-success)"-->
          <!--                  :active-value="0"-->
          <!--                  active-text="启用"-->
          <!--                  inactive-text="禁用"-->
          <!--                  :inactive-value="1"-->
          <!--                  @change="handleChange($event,row)"-->
          <!--                />-->
          <!--              </el-form-item>-->
          <!--              <template v-else>-->
          <!--                <el-tag v-if="row.offFlag" type="info">禁用</el-tag>-->
          <!--                <el-tag v-else type="success">启用</el-tag>-->
          <!--              </template>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column label="校区公告" width="160px">
            <template v-slot="{row,$index}">
              <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.announceText`"
                            :rules="rules.announceText">
                <el-input type="textarea" rows="3" size="small" v-model="row.announceText" />
              </el-form-item>
              <span v-else>{{ row.announceText }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" width="160px">
            <template v-slot="{row,$index}">
              <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.remarks`"
                            :rules="rules.remarks">
                <el-input type="textarea" rows="3" size="small" v-model="row.remarks" />
              </el-form-item>
              <span v-else>{{ row.remarks }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160px" fixed="right">
            <template v-slot="{row,$index}">
              <template v-if="row.isEditing">
                <el-form-item>
                  <el-button
                    type="primary" size="small"
                    v-if="row.isEditing" v-permission="['gym:rule:edit']" @click="save(row)">保存
                  </el-button>
                  <el-button size="small" @click="cancel(row,$index)">取消</el-button>
                </el-form-item>
              </template>
              <template v-else>
                <template v-if="!isEditing">
                  <el-button size="mini"
                             type="primary" plain
                             v-permission="['gym:rule:edit']"
                             @click="editRow(row,$index)">编辑
                  </el-button>
                  <el-button size="mini"
                             type="danger"
                             v-permission="['gym:rule:edit']"
                             @click="del(delGymRuleByIdApi, row.id,`排期规则-${row.name}`)">
                    删除
                  </el-button>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </table-view>
    <template #footer>
      <el-button @click="closed">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import dialog from '@/vue/mixins/dialog'
import Vue from 'vue'
import {
  delGymRuleByIdApi,
  pageGymRuleApi,
  saveGymRuleApi
} from '@/api/student-manage/gym-rule-api'

export default {
  name: 'GymRuleDialog',
  mixins: [dialog, tableView],
  props: {
    roleList: {
      type: Array,
      default: () => ([])
    },
    serviceList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      campusList: [],
      queryInfo: {
        campusId: ''
      },
      formData: {
        tableData: []
      },
      isEditor: false,
      isEditing: false,
      rules: {
        campusId: {
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        },
        name: [{
          required: true,
          message: '请输入规则名',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit20WordsObj],
        // dayOrderMax: {
        //   required: true,
        //   message: '请选择',
        //   trigger: 'blur'
        // },
        roomOrderMax: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        scheduleOrderMax: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        preFixedTime: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        cancelFixedTime: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        announceText: {
          max: 200,
          message: '不能超过200字',
          trigger: 'blur'
        },
        remarks: {
          max: 200,
          message: '不能超过200字',
          trigger: 'blur'
        }
      }
    }
  },
  mounted() {
    this.isEditor = this.$store.state.login.permissionTable.includes('gym:rule:edit')
    this.getCampusList()
    this.renderTable()
  },
  methods: {
    // 删除api
    delGymRuleByIdApi,
    async renderTable() {
      this.loading = true
      try {
        const res = await pageGymRuleApi(this.queryInfo)
        this.formData.tableData = res.data.list
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    // 获取校区
    async getCampusList() {
      try {
        const res = await this.$http.getCampusListQuery({})
        this.campusList = res.data
      } catch (e) {
      }
    },
    editRow(row, index) {
      this.isEditing = true
      row.isEditing = true
      Vue.set(this.formData.tableData, index, row)
    },
    newRow() {
      if (this.isEditing) {
        this.$message('请先保存已修改的数据，再进行新增操作！')
      } else {
        const _row = {
          campusId: '',
          isEditing: true,
          campusName: '',
          name: '',
          announceText: '',
          dayOrderMax: 1,
          roomOrderMax: 1,
          offFlag: 0,
          preFixedTime: 0,
          cancelFixedTime: 0,
          remarks: ''
        }
        this.formData.tableData.unshift(_row)
        this.isEditing = true
      }
    },
    async cancel(row, index) {
      row.isEditing = false
      this.isEditing = false
      Vue.set(this.formData.tableData, index, row)
      await this.renderTable()
    },
    handleChange(val, row) {
      if (val) {
        this.$confirm('禁用规则后，该校区的所有健身房将不可预约，是否继续？', '提示').then().catch(() => {
          row.offFlag = 0
        })
      }
    },
    save(row) {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.$confirm('点击确定校区排期规则。', '保存').then(async () => {
            try {
              await saveGymRuleApi(row)
              await this.renderTable()
              this.isEditing = false
            } catch (e) {
            }
          }).catch(() => {
          }).finally(() => {
            this.renderTable()
            this.isEditing = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.set-fix-service-dialog {
  .el-dialog {
    margin-top: 5vh !important;
  }

  .el-dialog__body {
    padding: 0 15px;

    .el-form {
      padding-right: 0;
    }
  }
}
</style>
