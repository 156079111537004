<template>
  <!--健身房闸机-->
  <el-dialog
    class="gym-door-dialog"
    :title="`健身房闸机管理-${parentData.name}【${parentData.campusName}】`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="closed"
    width="920px"
  >
    <div>
      <view-search-form
        ref="searchFormRef"
        class="flex-1"
        :tool-list="['keyword']"
        :queryInfo.sync="queryTableInfo"
        @on-search="renderTable(1)">
        <el-form-item label="是否启用">
          <el-select v-model="queryTableInfo.enable" size="small" placeholder="是否启用"
                     clearable>
            <el-option label="启用" :value="1" />
            <el-option label="禁用" :value="0" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </div>
    <div v-if="isEditor" class="mb-1 flex" style="justify-content: space-between">
      <div>
        {{ isEditing }}
        <el-button size="small" :disabled="isEditing" @click="showBatchBtn">{{ visibleBanBtn ? '取消禁用' : '批量禁用'
          }}
        </el-button>
        <el-button v-if="visibleBanBtn" size="small" type="danger" plain @click="handleBatchBan">确认禁用
        </el-button>
      </div>
      <el-button :disabled="visibleBanBtn||isEditing" type="primary" size="small" @click="newRow">新增闸机
      </el-button>
    </div>
    <el-form ref="formRef" v-if="visibleTable" :model="formData" v-loading="loading" :rules="rules"
             style="padding-right: 0;">
      <el-table :data="formData.tableData"
                @selection-change="handleSelectChange">
        <el-table-column v-if="visibleBanBtn" type="selection" width="50" align="center"
                         :selectable="handleRowSelectable" />
        <el-table-column label="健身房" prop="roomName" width="110px">
          <template v-slot="{row}">
            <span v-if="row.isEditing">{{ parentData.name }}</span>
            <span v-else>{{ row.roomName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="闸机名称" min-width="140px">
          <template v-slot="{row,$index}">
            <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.doorName`"
                          :rules="rules.doorName">
              <el-input size="small" v-model="row.doorName" maxlength="20" />
            </el-form-item>
            <span v-else>{{ row.doorName }}</span>
          </template>
        </el-table-column>
        <el-table-column width="220" align="center">
          <template #header>
            <tool-tips-group tips-content="禁用后，闸机将禁止出入。">是否禁用</tool-tips-group>
          </template>
          <template v-slot="{row,$index}">
            <!--    闸机不能随便删除，可以通过禁用代替删除。   -->
            <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.enable`">
              <el-switch
                v-if="isEditor"
                v-model="row.enable"
                active-color="var(--color-success)"
                :active-value="1"
                active-text="启用"
                inactive-text="禁用"
                :inactive-value="0"
              />
            </el-form-item>
            <template v-else>
              <el-tag v-if="row.enable" type="success">启用</el-tag>
              <el-tag v-else type="info">禁用</el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="备注" width="160px">
          <template v-slot="{row,$index}">
            <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.remarks`"
                          :rules="rules.remarks">
              <el-input type="textarea" rows="3" size="small" v-model="row.remarks" />
            </el-form-item>
            <span v-else>{{ row.remarks }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160px" fixed="right">
          <template v-slot="{row,$index}">
            <template v-if="row.isEditing">
              <el-form-item>
                <el-button
                  type="primary" size="small"
                  v-if="row.isEditing&&isEditor" @click="save(row)">保存
                </el-button>
                <el-button size="small" @click="cancel(row,$index)">取消</el-button>
              </el-form-item>
            </template>
            <template v-else>
              <template v-if="(!isEditing)&&isEditor">
                <el-button size="mini"
                           type="primary" plain
                           @click="editRow(row,$index)">编辑
                </el-button>
              </template>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <template #footer>
      <el-button @click="closed">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import dialog from '@/vue/mixins/dialog'
import Vue from 'vue'
import { listGymDoorByCourtIdApi, batchBanGymDoorApi, saveGymDoorApi } from '@/api/student-manage/gym-door-api'

export default {
  name: 'GymDoorDialog',
  mixins: [dialog, tableView],
  props: {
    roleList: {
      type: Array,
      default: () => ([])
    },
    serviceList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      visibleBanBtn: false,
      rowCheckList: [],
      parentData: {},
      campusList: [],
      visibleTable: true,
      queryTableInfo: {
        gymRoomId: null,
        enable: null,
        keyword: null
      },
      formData: {
        tableData: []
      },
      isEditor: false,
      isEditing: false,
      rules: {
        gymRoomId: {
          required: true,
          message: '请选择健身房',
          trigger: 'blur'
        },
        doorName: [{
          required: true,
          message: '请输入闸机名',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit20WordsObj],
        remarks: {
          max: 200,
          message: '不能超过200字',
          trigger: 'blur'
        }
      }
    }
  },
  mounted() {
    this.isEditor = this.$store.state.login.permissionTable.includes('gym:door:edit')
  },
  methods: {
    async renderTable() {
      this.loading = true
      if (!this.parentData.id) {
        this.close()
      }
      try {
        this.isEditing = false
        this.queryTableInfo.gymRoomId = this.parentData.id
        const res = await listGymDoorByCourtIdApi(this.queryTableInfo)
        this.formData.tableData = res.data
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    editRow(row, index) {
      this.isEditing = true
      row.isEditing = true
      Vue.set(this.formData.tableData, index, row)
    },
    newRow() {
      if (this.isEditing) {
        this.$message('请先保存已修改的数据，再进行新增操作！')
      } else {
        /*
        *  @params {string} doorDeviceNo 门锁设备编码
        *  @params {string} doorName 闸机名字
        *  @params {string} gymRoomId 绑定的健身房 id
        *  @params {string} enable 启用/禁用
        * */
        const _row = {
          id: '',
          isEditing: true,
          doorDeviceNo: '',
          doorName: '',
          enable: 1,
          gymRoomId: this.parentData.id,
          remarks: ''
        }
        this.formData.tableData.unshift(_row)
        this.isEditing = true
      }
    },
    async cancel(row, index) {
      row.isEditing = false
      this.isEditing = false
      Vue.set(this.formData.tableData, index, row)
      await this.renderTable()
    },
    handleChange(val, row) {
      if (val) {
        this.$confirm('禁用规则后，该校区的所有健身房将不可预约，是否继续？', '提示').then().catch(() => {
          row.offFlag = 0
        })
      }
    },
    showBatchBtn() {
      this.visibleTable = false
      this.visibleBanBtn = !this.visibleBanBtn
      this.$nextTick(() => {
        this.visibleTable = true
      })
    },
    // table checkbox控制,已禁用的禁选
    handleRowSelectable(row) {
      return !!row.enable
    },
    // table 多选控制,获取选中的id
    handleSelectChange(val) {
      while (this.rowCheckList.length) {
        this.rowCheckList.pop()
      }
      if (val.length) {
        val.forEach(({ id }) => {
          this.rowCheckList.push(id)
        })
      }
    },
    async handleBatchBan() {
      if (this.rowCheckList.length) {
        this.$confirm('是否禁用所选闸机？', '提示').then(async () => {
          this.loading = true
          try {
            await batchBanGymDoorApi(this.rowCheckList)
            this.$message.success('批量禁用成功')
            await this.renderTable()
            this.loading = false
            this.visibleBanBtn = false
          } catch (e) {
            console.error(e)
          }
        }).catch(() => {
        })
      } else {
        this.$message.warning('请先勾选闸机')
      }
    },
    save(row) {
      this.$refs.formRef.validate(valid => {
        const _msg = row.id ? '修改' : '新增'
        if (valid) {
          this.$confirm(`点击确定${_msg}健身房闸机。`, '保存确认').then(async () => {
            try {
              await saveGymDoorApi(row)
              this.$message.success(`${_msg}成功`)
              await this.renderTable()
              this.isEditing = false
            } catch (e) {
              console.error(e)
            }
          }).catch(() => {
          }).finally(() => {
            this.renderTable()
            this.isEditing = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.set-fix-service-dialog {
  .el-dialog {
    margin-top: 5vh !important;
  }

  .el-dialog__body {
    padding: 0 15px;

    .el-form {
      padding-right: 0;
    }
  }
}
</style>
